import React, { useState } from 'react';
import {Container, Grid} from "@mui/material";
import { Router,useLocation} from '@reach/router';
import FormWrapper from '../../components/forms/form-wrapper';
import FormInput from '../../components/forms/form-input';
import { OpenRoute } from '../../components/app-routes/app-routes';
import firebase from "gatsby-plugin-firebase";
import DialogBottomButton from '../../components/dialogs/dialog-bottom-button';
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";
import Text from '../../components/data-display/text';
import SecondaryButton from "../../components/buttons/secondary-button";
import {environment} from "../../environments/quickTaskEnvironemt";
import {project, isQuickTaskProject, title} from "../../utils/constant.urls";
  
  
const QuikTaskMainColor = environment.mainColor;
const styles ={
  // paper: {
  //     padding: "14px 24px", width: "300px", background: "white", borderRadius: "10px", height: "fit-content", marginBottom: "20px"
  // }, badge: {
  //     background: "white", borderRadius: '50%', width: '20px', height: '20px', border: '2px solid rgb(211,211,211,0.5)', padding: '2px', marginRight: '15px'
  // }, flex: {
  //     display: 'flex'
  // }
}

const ContactUs = () => {
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const submitForm = async (values: Record<string, string | boolean>) => {
    if (values) {
      await firebase
        .firestore()
        .collection("contact-us")
        .add({
          project: project,
          name: values.name.toString(),
          email: values.email.toString() ?? "",
          description: values.description?.toString() ?? "",
          created: new Date(),
        });
    }
  };

  return (
    <div>
      {
        <Container style={{marginTop: "200px", display: "flex"}}>
          <FormWrapper onSubmit={submitForm} reset>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Text variant="h1">Contact {title}</Text>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormInput name={"name"} placeholder={"Your Name"} label={"Name"} required />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <FormInput
                  name={"email"}
                  type={"email"}
                  placeholder={"Your Email"}
                  label={"Email"}
                  required
                />
              </Grid>
            </Grid>
            <div style={{height: "18px"}} />
            <FormInput
              name={"description"}
              type={"textarea"}
              multiline
              rowsMin={4}
              rows={4}
              rowsMax={4}
              placeholder={"Tell us more"}
              label={"Description"}
            />
            <SecondaryButton type="submit" style={{width: "360px"}}>
              <div
                style={{
                  backgroundColor: isQuickTaskProject ? QuikTaskMainColor : "rgb(78, 62, 253)",
                  width: "360px",
                  borderRadius: "100px",
                  height: "40px",
                }}
              >
                <span
                  style={{
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  {isQuickTaskProject ? "Send message" : "Submit Form"}
                </span>
              </div>
            </SecondaryButton>

            <div style={{height: "36px"}} />
          </FormWrapper>
        </Container>
      }
      <div style={{height: "100px"}} />
    </div>
  );
};


const App=()=> {
    const location= useLocation();
    return (
        <div className="content">
            <Router location={location}>
              <OpenRoute path="/contact-us" component={ContactUs}/>
            </Router>
         </div>
    )
  }
  
  export default App;
